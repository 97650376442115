var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: {
            title: _vm.type == "add" ? "添加设备" : "编辑设备",
            top: "2%",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "主机类型", prop: "mainframeType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeMainframeType },
                      model: {
                        value: _vm.ruleForm.mainframeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mainframeType", $$v)
                        },
                        expression: "ruleForm.mainframeType",
                      },
                    },
                    _vm._l(_vm.hostType, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备类型", prop: "eqType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectEqType },
                      model: {
                        value: _vm.ruleForm.eqType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "eqType", $$v)
                        },
                        expression: "ruleForm.eqType",
                      },
                    },
                    _vm._l(_vm.eqList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valuee, value: item.keyy },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectOne },
                      model: {
                        value: _vm.ruleForm.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "brandId", $$v)
                        },
                        expression: "ruleForm.brandId",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brandName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "房间类型", prop: "roomTypeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.ruleForm.roomTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "roomTypeId", $$v)
                        },
                        expression: "ruleForm.roomTypeId",
                      },
                    },
                    _vm._l(_vm.roomTypeSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.typeName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备名称", prop: "eqName" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入设备名称" },
                    model: {
                      value: _vm.ruleForm.eqName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "eqName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.eqName",
                    },
                  }),
                ],
                1
              ),
              _vm.ruleForm.mainframeType != _vm.r_Name
                ? _c(
                    "el-form-item",
                    { attrs: { label: "回路编号", prop: "loopNumber" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入回路编号" },
                        model: {
                          value: _vm.ruleForm.loopNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "loopNumber", $$v)
                          },
                          expression: "ruleForm.loopNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ruleForm.mainframeType == _vm.r_Name
                ? _c("el-form-item", { attrs: { label: "按键名称" } }, [
                    _c("div", { staticClass: "keyBar" }, [
                      _c(
                        "div",
                        { staticClass: "keyBar1" },
                        _vm._l(
                          _vm.ruleForm.equipmentButtonList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "按键" + (index + 1) + ":",
                                      "label-width": "80px",
                                      prop:
                                        "equipmentButtonList." +
                                        index +
                                        ".loopName",
                                      rules: _vm.rules.loopName,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "loopCountBar" },
                                      [
                                        _vm.ruleForm.eqType == _vm.r3000.scene
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  filterable: "",
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: item.loopName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "loopName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.loopName",
                                                },
                                              },
                                              _vm._l(
                                                _vm.SceneTypes,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.sceneCodeSelf(
                                                            item.keyy
                                                          ),
                                                        expression:
                                                          "sceneCodeSelf(item.keyy)",
                                                      },
                                                    ],
                                                    key: index,
                                                    attrs: {
                                                      disabled:
                                                        _vm.disabledScene(
                                                          item.keyy +
                                                            _vm.and +
                                                            item.valuee
                                                        ),
                                                      label: item.valuee,
                                                      value:
                                                        item.keyy +
                                                        _vm.and +
                                                        item.valuee,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm.ruleForm.eqType ==
                                              _vm.r3000.clear && index < 2
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  filterable: "",
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: item.loopName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "loopName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.loopName",
                                                },
                                              },
                                              _vm._l(
                                                _vm.clearSceneTypes,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      disabled:
                                                        _vm.disabledScene(
                                                          item.keyy +
                                                            _vm.and +
                                                            item.valuee
                                                        ),
                                                      label: item.valuee,
                                                      value:
                                                        item.keyy +
                                                        _vm.and +
                                                        item.valuee,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请输入回路",
                                              },
                                              model: {
                                                value: item.loopName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "loopName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.loopName",
                                              },
                                            }),
                                        index == 0
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus-outline",
                                              on: { click: _vm.addLoop },
                                            })
                                          : _vm._e(),
                                        index > 0
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-remove-outline",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeLoop(index)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _vm.ruleForm.eqType == _vm.r3000.scene
                        ? _c(
                            "div",
                            { staticClass: "keyBar1" },
                            [
                              _vm._l(
                                _vm.ruleForm.eqKeyList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              "回路按键" + (index + 1) + ":",
                                            "label-width": "100px",
                                            prop:
                                              "eqKeyList." +
                                              index +
                                              ".loopName",
                                            rules: _vm.rules.loopName,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "loopCountBar" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请输入回路按键",
                                                },
                                                model: {
                                                  value: item.loopName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "loopName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.loopName",
                                                },
                                              }),
                                              index == 0
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-circle-plus-outline",
                                                    on: {
                                                      click: _vm.addLoopKey,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeLoopKey(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.ruleForm.eqKeyList.length == 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-empty",
                                        {
                                          attrs: {
                                            description: "未设置回路按键",
                                            size: "mini",
                                            "image-size": 80,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: { click: _vm.addLoopKey },
                                            },
                                            [_vm._v("添加回路按键")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm.ruleForm.mainframeType != _vm.r_Name &&
              _vm.ruleForm.eqType == _vm.tgModel
                ? _c(
                    "el-form-item",
                    { attrs: { label: "调光灯" } },
                    _vm._l(
                      _vm.ruleForm.equipmentTgLightList,
                      function (item, index) {
                        return _c(
                          "el-row",
                          { key: index },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "调光灯类型",
                                      "label-width": "120px",
                                      prop:
                                        "equipmentTgLightList." +
                                        index +
                                        ".eqType",
                                      rules: _vm.rules.eqType,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: item.eqType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "eqType", $$v)
                                          },
                                          expression: "item.eqType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.eq_tg_list,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.valuee,
                                              value: item.keyy,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "设备名字",
                                      "label-width": "120px",
                                      prop:
                                        "equipmentTgLightList." +
                                        index +
                                        ".eqName",
                                      rules: _vm.rules.lightName,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请调光名字",
                                      },
                                      model: {
                                        value: item.eqName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "eqName", $$v)
                                        },
                                        expression: "item.eqName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "回路",
                                      "label-width": "120px",
                                      prop:
                                        "equipmentTgLightList." +
                                        index +
                                        ".loopNumber",
                                      rules: _vm.rules.tgloopNumber,
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: { min: 1, max: 18 },
                                      model: {
                                        value: item.loopNumber,
                                        callback: function ($$v) {
                                          _vm.$set(item, "loopNumber", $$v)
                                        },
                                        expression: "item.loopNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "reset-button",
                                attrs: { span: 3 },
                              },
                              [
                                index == 0
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-circle-plus-outline",
                                      on: { click: _vm.addTgLoop },
                                    })
                                  : _vm._e(),
                                index > 0
                                  ? _c("i", {
                                      staticClass: "el-icon-remove-outline",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeTgLoop(index)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "描述", prop: "describes" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      clearable: "",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.ruleForm.describes,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "describes", $$v)
                      },
                      expression: "ruleForm.describes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.formloading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }