<template>
 <div>
  <!-- 编辑的弹框 -->
  <el-dialog :title="type == 'add' ? '添加设备' : '编辑设备'" top="2%" :visible.sync="dialogFormVisible" :close-on-click-modal="false" class="astrict">
   <el-form :model="ruleForm" ref="ruleForm" :rules="rules" :label-width="formLabelWidth">
    <el-form-item label="主机类型" prop="mainframeType">
     <el-select placeholder="请选择" @change="changeMainframeType" v-model="ruleForm.mainframeType">
      <el-option v-for="(item, index) in hostType" :key="index" :label="item" :value="item"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="设备类型" prop="eqType">
     <el-select v-model="ruleForm.eqType" @change="selectEqType" clearable filterable placeholder="请选择">
      <el-option v-for="(item, index) in eqList" :key="index" :label="item.valuee" :value="item.keyy"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="品牌" prop="brandId">
     <el-select v-model="ruleForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
      <el-option v-for="(item, index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="房间类型" prop="roomTypeId">
     <el-select v-model="ruleForm.roomTypeId" clearable filterable placeholder="请选择">
      <el-option v-for="(item, index) in roomTypeSelectData" :key="index" :label="item.typeName" :value="item.id"></el-option>
     </el-select>
    </el-form-item>

    <el-form-item label="设备名称" prop="eqName">
     <el-input v-model.trim="ruleForm.eqName" clearable placeholder="请输入设备名称"></el-input>
    </el-form-item>
    <el-form-item label="回路编号" prop="loopNumber" v-if="ruleForm.mainframeType != r_Name">
     <el-input v-model="ruleForm.loopNumber" clearable placeholder="请输入回路编号"></el-input>
    </el-form-item>
    <el-form-item label="按键名称" v-if="ruleForm.mainframeType == r_Name">
     <div class="keyBar">
      <div class="keyBar1">
       <div v-for="(item, index) in ruleForm.equipmentButtonList" :key="index">
        <el-form-item :label="'按键' + (index + 1) + ':'" label-width="80px" :prop="'equipmentButtonList.' + index + '.loopName'" :rules="rules.loopName">
         <div class="loopCountBar">
          <el-select v-if="ruleForm.eqType == r3000.scene" v-model="item.loopName" clearable filterable placeholder="请选择">
           <el-option v-for="(item, index) in SceneTypes" :key="index" :disabled="disabledScene(item.keyy + and + item.valuee)" v-show="sceneCodeSelf(item.keyy)" :label="item.valuee" :value="item.keyy + and + item.valuee"></el-option>
          </el-select>
          <el-select v-else-if="ruleForm.eqType==r3000.clear&&index<2" v-model="item.loopName" clearable filterable placeholder="请选择">
           <el-option v-for="(item, index) in clearSceneTypes" :key="index" :disabled="disabledScene(item.keyy + and + item.valuee)" :label="item.valuee" :value="item.keyy + and + item.valuee"> </el-option>

          </el-select>

          <el-input v-model="item.loopName" v-else clearable placeholder="请输入回路"></el-input>

          <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addLoop"></i>
          <i class="el-icon-remove-outline" v-if="index > 0" @click="removeLoop(index)"></i>

         </div>
        </el-form-item>

       </div>

      </div>
      <div class="keyBar1" v-if="ruleForm.eqType == r3000.scene">

       <div v-for="(item, index) in ruleForm.eqKeyList" :key="index">
        <el-form-item :label="'回路按键' + (index + 1) + ':'" label-width="100px" :prop="'eqKeyList.' + index + '.loopName'" :rules="rules.loopName">
         <div class="loopCountBar">
          <el-input v-model="item.loopName" clearable placeholder="请输入回路按键"></el-input>

          <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addLoopKey"></i>
          <i class="el-icon-remove-outline" @click="removeLoopKey(index)"></i>

         </div>
        </el-form-item>

       </div>
       <div v-if="ruleForm.eqKeyList.length==0">

        <el-empty description="未设置回路按键" size="mini" :image-size="80">
         <el-button size="mini" type="primary" @click="addLoopKey">添加回路按键</el-button>
        </el-empty>
       </div>

      </div>

     </div>
    </el-form-item>
    <el-form-item label="调光灯" v-if="ruleForm.mainframeType != r_Name&&ruleForm.eqType==tgModel">
    
      <el-row v-for="(item, index) in ruleForm.equipmentTgLightList" :key="index">
       <el-col :span="7">
        <el-form-item label="调光灯类型" label-width="120px" :prop="'equipmentTgLightList.' + index + '.eqType'" :rules="rules.eqType">
         <!-- <div class="loopCountBar"> -->
         <el-select v-model="item.eqType" clearable filterable placeholder="请选择">
          <el-option v-for="(item, index) in eq_tg_list" :key="index" :label="item.valuee" :value="item.keyy"></el-option>
         </el-select>

         
        </el-form-item>
       </el-col>
       <el-col :span="7">
        <el-form-item label="设备名字" label-width="120px" :prop="'equipmentTgLightList.' + index + '.eqName'" :rules="rules.lightName">
        

         <el-input v-model="item.eqName" clearable placeholder="请调光名字"></el-input>

         
        </el-form-item>
       </el-col>
        <el-col :span="7">
        <el-form-item label="回路" label-width="120px" :prop="'equipmentTgLightList.' + index + '.loopNumber'" :rules="rules.tgloopNumber">
          <el-input-number :min="1" :max="18" v-model="item.loopNumber"></el-input-number>
        
        </el-form-item>
       </el-col>
       <el-col :span="3" class="reset-button">
          <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addTgLoop"></i>
          <i class="el-icon-remove-outline" v-if="index > 0" @click="removeTgLoop(index)"></i>
       </el-col>
      </el-row>

      <!-- </div>

      </div> -->
    </el-form-item>

    <el-form-item label="描述" prop="describes">
     <el-input type="textarea" :rows="5" clearable placeholder="请输入内容" v-model="ruleForm.describes"></el-input>
    </el-form-item>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitForm('ruleForm')" :loading="formloading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import {
 addLoopMsg,
 removeLoopInfo,
 sceneCodeSelfShow,
 getDataReqKey,
 disabledSceneKey,
 initEqListFunction,
} from "@/api/eqInfoCommon.js";
import {
 getEqlList,
 getEqSelect,
 addEq,
 getEqTypeSelect,
 getRoomTypelSelect,
 getBrandSelect,
 delEq,
 getRights,
 editEq,
 getSceneSelect,
 getEqDetail,
 getEqTGlist,
} from "@/api";
export default {
 props: {
  eqTypeSelectData: {
   type: Array,
  },
  brandSelectData: {
   type: Array,
  },
  SceneTypes: {
   type: Array,
  },
 },
 data() {
  return {
   dialogFormVisible: false,
   formloading: false,
   type: null,
   formLabelWidth: "100px",
   tgModel: "TG",
   TG_DENG: "TG_DENG",
   r_Name: "R3000",
   and: "&and&",
   // 编辑
   ruleForm: {
    brandId: null, //品牌id
    roomTypeId: null, //房间类型
    mainframeType: null,
    eqType: null, //设备类型
    eqName: null, //设备名称
    loopNumber: null, //回路编号
    eqCode: null,
    describes: null, //描述
    equipmentButtonList: [],
    eqKeyList: [],
    id: null,
    equipmentTgLightList: [
     {
      eqType: null,
      loopNumber:1,
      eqName: null,
     },
    ],
    // parentId:null
   },
   tgEqList: null,
   rules: {
    // parentId: [{ required: true, message: "请选择", trigger: "change" }],
    tgloopNumber: [
     { required: true, message: "请输入调光灯回路", trigger: ["blur", "change"] },
    ],
    lightName: [
     { required: true, message: "调光灯名字未设置", trigger: ["blur", "change"] },
    ],
    mainframeType: [{ required: true, message: "请选择", trigger: "change" }],
    eqName: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
    loopName: [
     { required: true, message: "按键未设置", trigger: ["blur", "change"] },
    ],
    // hardwareId: [{ required: true, message: "请输入内容", trigger: "blur" }],
    eqCode: [{ required: true, message: "请输入设备ID", trigger: "blur" }],
    loopNumber: [
     { required: true, message: "请输入回路编号", trigger: "blur" },
     // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
    ],
    equipmentButtonList: [
     { required: true, message: "请设置按键", trigger: "blur" },
     // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
    ],
    eqKeyList: [
     { required: true, message: "请设置回路", trigger: "blur" },
     // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
    ],

    brandId: [{ required: true, message: "请选择", trigger: "change" }],
    eqType: [{ required: true, message: "请选择", trigger: "change" }],
    roomTypeId: [{ required: true, message: "请选择", trigger: "change" }],
    roomType: [{ required: true, message: "请选择", trigger: "change" }],
   },
   roomTypeSelectData: null,
  };
 },
 computed: {
  eqList() {
   if (this.eqTypeSelectData) {
    if (this.ruleForm.mainframeType == this.r_Name)
     return this.eqTypeSelectData.filter(
      (item) => item.valuee.indexOf(this.r_Name) > -1
     );
    return this.eqTypeSelectData.filter(
     (item) => item.valuee.indexOf(this.r_Name) == -1
    );
   }
   return this.eqTypeSelectData;
  },
  eq_tg_list() {
   return this.eqList.filter((item)=>item.keyy.indexOf(this.TG_DENG) != -1);
  },
  hostType() {
   return this.$store.state.hostType;
  },
  bySelfCode() {
   return this.$store.state.bySelfCode;
  },
  clearSceneHas() {
   return this.$store.state.clearSceneHas;
  },
  r3000() {
   return this.$store.state.r3000;
  },
  clearSceneTypes() {
   return this.SceneTypes.filter((item) =>
    this.clearSceneHas.includes(item.keyy)
   );
  },
 },
 methods: {
  // 重置
  resetForm() {
  //  this.ruleForm = {
  //   brandId: null, //品牌id
  //   roomTypeId: null, //房间类型
  //   mainframeType: null,
  //   eqType: null, //设备类型
  //   eqName: null, //设备名称
  //   loopNumber: null, //回路编号
  //   eqCode: null,
  //   describes: null, //描述
  //   equipmentButtonList: [],
  //   eqKeyList: [],
  //   id: null,
  //   equipmentTgLightList: [
  //    {
  //     eqType: null,
  //     loopNumber:1,
  //     eqName: null,
  //    },
  //   ],
  //   //  parentId:null
  //  };
  },
  // changeRoomTypeGetModel(val){
  //   // this.tgEqList=[]

  //   // if(val&&this.ruleForm.eqType==this.tgModel){
  //   //   this.getTgListReq(val)
  //   // }
  // },
  // getTgListReq(type){
  //   getEqTGlist(type).then(res=>{
  //     this.tgEqList=res.data.data
  //   })
  // },
  selectOne(id) {
   this.ruleForm.roomTypeId = null;
   getRoomTypelSelect(id).then((res) => {
    if (res.status === 200) {
     this.roomTypeSelectData = res.data.data;
    }
   });
  },
  getData(dataForm) {
   let data = new Object();
   [
    "brandId",
    "roomTypeId",
    "eqType",
    "eqName",
    "loopNumber",
    "describes",
    "mainframeType",
   ].forEach((key) => {
    data[key] = dataForm[key];
   });
  if(data.eqType==this.tgModel){
    data.equipmentTgLightList=dataForm.equipmentTgLightList
  }
   let data2 = getDataReqKey(
    dataForm,
    this.r_Name,
    this.r3000,
    this.and,
    this.bySelfCode
   );
   data = Object.assign(data, data2);
   return data;
  },
  disabledScene(key) {
   return disabledSceneKey(key, this.ruleForm.equipmentButtonList);
  },
  sceneCodeSelf(code) {
   return sceneCodeSelfShow(code, this.bySelfCode);
  },
  addTgLoop(){
    if(this.ruleForm.equipmentTgLightList&&this.ruleForm.equipmentTgLightList.length>=18){
      this.$message.error('调光模块最多拥有 18 个设备')
      return
    }
    this.ruleForm.equipmentTgLightList.push({
      eqType: null,
      loopNumber:this.ruleForm.equipmentTgLightList.length+1,
      eqName: null,
     });
   this.$forceUpdate();
  },
  removeTgLoop(index) {
  //  let equipmentTgLightList = removeLoopInfo(index, this.ruleForm, this.r3000);
    this.ruleForm.equipmentTgLightList.splice(index,1)
  //  this.$set(this.ruleForm, "equipmentTgLightList", equipmentTgLightList);
   this.$forceUpdate();
  },
  addLoop() {
   if (!addLoopMsg(this.ruleForm, this.r3000)) return;
   this.ruleForm.equipmentButtonList.push({ loopName: undefined });
   this.$forceUpdate();
  },
  removeLoop(index) {
   let equipmentButtonList = removeLoopInfo(index, this.ruleForm, this.r3000);
   this.$set(this.ruleForm, "equipmentButtonList", equipmentButtonList);
   this.$forceUpdate();
  },
  addLoopKey() {
   console.log(addLoopMsg(this.ruleForm, this.r3000, "eqKeyList"));
   if (!addLoopMsg(this.ruleForm, this.r3000, "eqKeyList")) return;
   this.ruleForm.eqKeyList.push({ loopName: undefined });
   this.$forceUpdate();
  },
  removeLoopKey(index) {
   let eqKeyList = removeLoopInfo(
    index,
    this.ruleForm,
    this.r3000,
    "eqKeyList"
   );
   this.$set(this.ruleForm, "eqKeyList", eqKeyList);
   this.$forceUpdate();
  },

  changeMainframeType(val) {
   if (val) {
    this.ruleForm.eqType = null;
    this.initEQ();
   }
  },
  selectEqType(val) {
    if(val==this.tgModel&&(!this.ruleForm.equipmentTgLightList||this.ruleForm.equipmentTgLightList.length==0)){
      let arr=[]
      arr.push({
         eqType: null,
      loopNumber:1,
      eqName: null,
      })
this.ruleForm.equipmentTgLightList=arr
    }
   this.initEQ();
  },
  initEQ() {
   if (this.ruleForm.mainframeType == this.r_Name) {
    this.ruleForm.equipmentButtonList = [];
    this.ruleForm.equipmentButtonList.push({ loopName: undefined });
    if (this.ruleForm.eqType == this.r3000.scene) {
     this.ruleForm.eqKeyList = [];
     this.ruleForm.eqKeyList.push({ loopName: undefined });
    }
   }
  },
  /* 新增弹窗 */
  addBtn(val) {
   this.dialogFormVisible = true;

   if (val == "add" && this.type == "edit") {
    this.resetForm();
    setTimeout(() => {
     this.$refs.ruleForm.clearValidate();
    }, 300);
   }
   this.type = val;
  },
  open(type, val) {
   this.dialogFormVisible = true;
   if (type == "add" && this.type == "edit") {
    this.resetForm();
    setTimeout(() => {
     this.$refs.ruleForm.clearValidate();
    }, 300);
   }
   this.type = type;
   if (val) {
    this.getEqDetailReq(val);
   }
  },
  // 编辑楼层
  getEqDetailReq(val) {
   // console.log(index);
   //  this.addBtn("edit");
   getEqDetail(val.id).then((res) => {
    console.log(res, "---------------");
    this.ruleForm = res.data.data;
    this.ruleForm = initEqListFunction(
     this.ruleForm.mainframeType,
     this.r_Name,
     this.ruleForm,
     this.r3000,
     this.and
    );
    // if(this.ruleForm.eqType=="TG_DENG"){
    //   this.getTgListReq(this.ruleForm.roomTypeId)
    // }
    this.$forceUpdate();
   });
   getRoomTypelSelect(val.brandId).then((res) => {
    if (res.status === 200) {
     this.roomTypeSelectData = res.data.data;
     // console.log(res)
    }
   });
  },
  // 表单确认
  submitForm(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     let data = this.getData(this.ruleForm);
     console.log(data);
     if (this.type == "add") {
      console.log(data, "add");
      this.addEq(data);
     } else {
      data.eqId = this.ruleForm.eqId; //设备ID
      console.log(data, "editEq");
      this.editEq(data);
     }
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },

  editEq(data) {
   this.formloading = true;
   editEq(data)
    .then((res) => {
     this.formloading = false;
     if (res.data.code == "000000") {
      this.$message.success(res.data.message);
      this.dialogFormVisible = false;
      this.resetForm();
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     this.formloading = false;
    });
  },
  addEq(data) {
   this.formloading = true;
   addEq(data)
    .then((res) => {
     this.formloading = false;
     if (res.data.code == "000000") {
      this.$message.success(res.data.message);
      this.dialogFormVisible = false;
      this.resetForm();
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     this.formloading = false;
     console.log(err);
    });
  },
 },
};
</script>
<style lang="scss" scoped>
.loopCountBar {
 width: 250px;
 display: flex;
 align-items: center;
 .kClass {
  width: 30px;
  // margin-right: 20px;
 }
}
 i {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
 }
.loopCountBar:nth-of-type(n + 2) {
 margin-top: 10px;
}
.keyBar {
 display: flex;
 // align-items: flex-start;
}
.keyBar1 {
 width: 50%;
 //  display: inline-block;
}
.tgBar {
 display: flex;
 flex-wrap: wrap;
}
.tgItem {
 width: 50%;
}
</style>