<template>
 <div class="my-box" v-loading="loading">
  <!-- 功能区域 -->
  <el-row>
   <el-card shadow="always">
    <el-form label-width="80px" class="topS">
     <el-row :gutter="10">
      <el-col :span="3">
       <el-button @click="addBtn('add')" v-if="showadd != -1">新增设备</el-button>
       <el-button @click="batchesDelete" v-if="showdelete != -1">批量删除</el-button>
      </el-col>
      <el-col :span="3">
       <el-form-item label="品牌">
        <el-select v-model="seekData.brandId" clearable filterable @change="selectOne" placeholder="请选择">
         <el-option v-for="(item, index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="3">
       <el-form-item label="主机类型">
        <el-select v-model="seekData.mainframeType" clearable filterable placeholder="请选择">
         <el-option v-for="(item, index) in hostType" :key="index" :label="item" :value="item"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="房间类型" >
        <el-select v-model="seekData.roomTypeId" clearable filterable placeholder="请选择">
         <el-option v-for="(item, index) in roomTypeSelectData" :key="index" :label="item.typeName" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>

      <el-col :span="4">
       <el-form-item label="设备类型" >
        <el-select v-model="seekData.eqType" clearable filterable placeholder="请选择">
         <el-option v-for="(item, index) in eqTypeSelectData" :key="index" :label="item.valuee" :value="item.keyy"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="设备名称">
        <el-input placeholder="请输入内容" v-model="seekData.eqName" clearable class="my-input"></el-input>
       </el-form-item>
      </el-col>
      <el-col :span="3" class="reset-button">
       <el-button type="primary" @click="handleSearch">查询</el-button>
       <el-button @click="resetList">重置</el-button>
      </el-col>
     </el-row>
    </el-form>
   </el-card>
  </el-row>
  <!-- 内容区域 -->
  <el-row>
   <el-card shadow="always">
    <div class="my-header-table">
     <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :height="height" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="mainframeType" label="主机类型"></el-table-column>

      <el-table-column prop="roomTypeName" label="房间类型"></el-table-column>
      <el-table-column prop="eqTypeName" label="设备类型" sortable></el-table-column>
      <el-table-column prop="eqName" label="设备名称"></el-table-column>

      <el-table-column prop="loopNumber" label="回路编号"></el-table-column>
      <el-table-column label="操作" width="150">
       <template slot-scope="scope">
        <!-- 编辑按钮 -->
        <el-button v-if="showdelete != -1" type="primary" size="mini" @click="handleEdit(scope.row)">修改</el-button>
        <!-- 删除按钮 -->
        <el-button v-if="showdelete != -1" size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
       </template>
      </el-table-column>
     </el-table>
     <el-pagination background layout="prev, pager, next, sizes, total, jumper" :page-sizes="[10, 20, 50, 100]" :page-size="seekData.pageSize" :current-page="seekData.pageNum" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
   </el-card>
  </el-row>
  <diaoView  :brandSelectData="brandSelectData"  :eqTypeSelectData="eqTypeSelectData" :SceneTypes="SceneTypes" ref="diaoView"/>
 </div>
</template>
<script>
import diaoView from './diaoview.vue'
import {
 getEqlList,
 getEqSelect,
 addEq,
 getEqTypeSelect,
 getRoomTypelSelect,
 getBrandSelect,
 delEq,
 getRights,
 editEq,
 getSceneSelect,
 getEqDetail,
} from "@/api";

export default {
  components:{
    diaoView
  },
 // name:"sbgl",
 data() {
  return {
 
   height: null,
   multipleSelection: [],
   loading: false,
   // 建筑列表数据
   tableData: null,
   dialogFormVisible: false,
   // 查询的数据
   total: null,
   seekData: {
    mainframeType: null,
    brandId: null, //品牌id
    roomTypeId: null, //房間类型id
    eqType: null,
    eqName: null,
    pageSize: 10,
    pageNum: 1,
   },
   // 下拉框的数据
   brandSelectData: null,
   eqTypeSelectData: null,
   SceneTypes: [],
   roomTypeSelectData: null,
  
  };
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 240;
 },
 computed: {
  hostType() {
   return this.$store.state.hostType;
  },
  // 权限
  showadd() {
   return this.userJurisdiction.indexOf("equipmentInfo:add");
  },
  showdelete() {
   return this.userJurisdiction.indexOf("equipmentInfo:delete");
  },
  showupdate() {
   return this.userJurisdiction.indexOf("equipmentInfo:update");
  },
 },
 created() {
  this.initialize();
  this.userJurisdiction = this.$store.state.roleMenu;
  // 调用初始化表格数据方法
  this.initList(this.seekData);
 },
 methods: {
  // 初始化表格数据
  initList(obj) {
   this.loading = true;
   getEqlList(obj).then((res) => {
    this.loading = false;
    if (res.status === 200) {
     this.tableData = res.data.data.rows;
     this.total = res.data.data.total;
    }
   });
  },
  /* 初始化下拉框 */
  // 获取品牌下拉框
  initialize(bid) {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
     // console.log(res)
    }
   });
   getEqTypeSelect().then((res) => {
    if (res.status === 200) {
     this.eqTypeSelectData = res.data.data;
    }
   });
   getSceneSelect().then((res) => {
    if (res.status === 200) {
     this.SceneTypes = res.data.data;
    }
   });
  },
  // 房间类型下拉框
  selectOne(id) {
   this.seekData.roomTypeId = null;
   getRoomTypelSelect(id).then((res) => {
    if (res.status === 200) {
     this.roomTypeSelectData = res.data.data;
    }
   });
  },

  /* 新增弹窗 */
  addBtn(type,val) {
  //  this.dialogFormVisible = true;
  this.$refs.diaoView.open(type,val)
   
  },
  // 楼层删除
  handleDelete(row) {
   // console.log(row)
   this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     // 点击确定执行的操作
     // console.log(row.id)
     delEq(row.id).then((res) => {
      console.log(res);
      if (res.status === 200) {
       this.$message.success("删除成功");
       this.initList(this.seekData);
      } else {
       this.$message.error("删除失败");
      }
     });
    })
    .catch(() => {
     this.$message({
      type: "info",
      message: "已取消删除",
     });
    });
  },

  batchesDelete() {
   if (this.multipleSelection.length != 0) {
    // 把要删除的用户ID以字符串拼接
    let number = "";
    for (let i = 0; i < this.multipleSelection.length; i++) {
     const element = this.multipleSelection[i];
     number += element.id + ",";
    }
    number = number.slice(0, number.length - 1); //去掉最后的逗号
    this.$confirm("此操作将永久删除所有选择项, 是否继续?", "提示", {
     confirmButtonText: "确定",
     cancelButtonText: "取消",
     type: "warning",
    })
     .then(() => {
      this.loading = true;
      delEq(number).then((res) => {
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success(res.data.message);
        this.initList(this.seekData);
       } else {
        this.$message.error(res.data.message);
       }
      });
     })
     .catch(() => {
      this.loading = false;
      this.$message({
       type: "info",
       message: "已取消删除",
      });
     });
   } else {
    this.$message.warning("请先选择要删除的数据");
   }
  },
  handleSelectionChange(val) {
   this.multipleSelection = val;
   // console.log(val)
  },
  handleEdit(row) {
   this.addBtn("edit", row);
  },

  // 查询按钮
  handleSearch() {
   // console.log(this.seekData)
   this.seekData.pageNum = 1;
   if (this.seekData) {
    this.loading = true;
    getEqlList(this.seekData).then((res) => {
     // console.log(res)
     if (res.status === 200) {
      this.tableData = res.data.data.rows;
      this.total = res.data.data.total;
      this.loading = false;
     }
    });
   } else {
    this.initList(this.seekData);
   }
  },
 
  resetList() {
   ["brandId", "roomTypeId", "eqType", "eqName", "mainframeType"].forEach(
    (key) => (this.seekData[key] = null)
   );
   this.initList(this.seekData);
  },
  //分页
  handleCurrentChange(page) {
   this.seekData.pageNum = page;
   this.initList(this.seekData);
  },
  handleSizeChange(pageSize) {
   this.seekData.pageSize = pageSize;
   this.seekData.pageNum = 1;
   this.initList(this.seekData);
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 .my-input {
  max-width: 215px;
 }
 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }

}

</style>
