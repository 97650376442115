import { render, staticRenderFns } from "./sbgl.vue?vue&type=template&id=db5cb742&scoped=true&"
import script from "./sbgl.vue?vue&type=script&lang=js&"
export * from "./sbgl.vue?vue&type=script&lang=js&"
import style0 from "./sbgl.vue?vue&type=style&index=0&id=db5cb742&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db5cb742",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nginx/static/iot-cloud-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db5cb742')) {
      api.createRecord('db5cb742', component.options)
    } else {
      api.reload('db5cb742', component.options)
    }
    module.hot.accept("./sbgl.vue?vue&type=template&id=db5cb742&scoped=true&", function () {
      api.rerender('db5cb742', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/baseInfo/sbgl/sbgl.vue"
export default component.exports